import React, { useState } from 'react';
import "./form.css";
import { db } from "../../firebase"
import { uid } from "uid"
import { set, ref } from "firebase/database"
import ReactGA from "react-ga4"

const Form = () => {
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState("less than $500");

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const writeToDatabase = () => {
    if (!email) {
      console.log("error");
      return;
    }
  
    const uuid = uid();
    set(ref(db, `orders/${uuid}`), {
      subject: subject,
      email: email,
      message: message,
    })
    .then(() => {
      console.log("success");
    })
    .catch((error) => {
      setMessage("Error");
      console.error('Error:', error);
    });
  };  

  const handleSubmit = (e) => {
    ReactGA.event({"category":"Form", "action":"submit", "label":"label"});
    e.preventDefault();

    writeToDatabase();

    // Reset form fields
    setSubject("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className='form'>
      <form onSubmit={handleSubmit}>
        <div className='form-top'>
          <div className='form-subject'>
            <input required placeholder='Subject' type="text" id="subject" value={subject} onChange={handleSubjectChange} />
          </div>
          <div className='form-email'>
            <input required placeholder='Your email' type="email" id="email" value={email} onChange={handleEmailChange} />
          </div>
        </div>
        <div className='form-message'>
          <textarea required placeholder="What can we help you with?" id="message" value={message} onChange={handleMessageChange} />
        </div>
        {/* <div className='form-budget'>
          <label htmlFor="budget">What is your budget for the project?</label>
          <select required id="budget" value={budget} onChange={handleBudgetChange}>
            <option value="less than $500">less than $500</option>
            <option value="$500 - $1,000">$500 - $1,000</option>
            <option value="$1,000 - $2,000">$1,000 - $2,000</option>
            <option value="$2,000 - $4,000">$2,000 - $4,000</option>
            <option value="$4,000+">$4,000+</option>
          </select>
        </div> */}
        {/* <div className='form-checkbox'>
          <label class="form-checkbox-label">
            Free consultation
              <input type="checkbox"  checked={consultation} onChange={handleCheckboxFreeConsultation} />
              <span class="form-checkmark"></span>
          </label>
        </div> */}
        <div className='form-send'>
          <button type="submit">Send</button>
        </div>
      </form>
    </div>
  );
};

export default Form;
