import React from 'react'
import { Footer, Navbar } from '../../components'
import { About, Banner, Contact, Hero, Services, Team } from '../../containers'

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Banner />
      <Team />
      <Contact />
      <Footer />
    </div>
  )
}

export default Home
