import React from 'react'
import './servicecard.css'

const ServiceCard = ({ title, text }) => {
  return (
    <div className='service-card'>
      <div className='service-card_body'>
            <h3>{title}</h3>
            <p>{text}</p>
      </div>
    </div>
  )
}

export default ServiceCard
