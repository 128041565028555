import { initializeApp } from "firebase/app";
import { getDatabase} from "firebase/database"
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAsNGtB9CID2eepbnqi6cDYJq3Ej8O56BU",
    authDomain: "article-website-e30d7.firebaseapp.com",
    databaseURL: "https://article-website-e30d7-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "article-website-e30d7",
    storageBucket: "article-website-e30d7.appspot.com",
    messagingSenderId: "139655053988",
    appId: "1:139655053988:web:3260df2af1474c88b99c31",
    measurementId: "G-6F6270GTZT"
  };

  const app = initializeApp(firebaseConfig);
  export const db = getDatabase(app);
  export const storage = getStorage(app);