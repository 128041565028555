import React from "react";
import "./banner.css";

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        {/* <p>Get Notified When We Launch!</p> */}
        <p>Coming soon...</p>
        <h3>We Are Launching our Customizeable Chatbot.</h3>
      </div>
      <div className="banner-btn">
        {/* <button type="button">Read More</button> */}
      </div>
    </div>
  )
};

export default Banner;
