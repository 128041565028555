import React from 'react'
import "./navbar.css"
import logo from "../../assets/logo.png"

const Navbar = () => {
  return (
    <div className='navbar-content'>
      <div className='navbar-content_links'>
        <img src={logo} />
        <a href='#services'>Our services</a>
        <a href="#contact"><button type="button">Contact</button></a>
      </div>
    </div>
  )
}

export default Navbar
