import React from 'react'
import "./about.css"

const About = () => {
  return (
    <div className='about-us section-padding'>
        <h1>About Us</h1>      
        <p>
            At OmiumAI, we create custom solutions for businesses using artificial intelligence. We believe every business can benefit from using our services. 
            Experience the benefits of increased efficiency, improved customer satisfaction, and reduced costs. 
            Let us help you unlock the full potential of AI and elevate your business to new heights.
        </p>
    </div>
  )
}

export default About
