import React from 'react'
import "./contact.css"
import { Form } from '../../components'

const Contact = () => {
  return (
    <div className='contact-us' id='contact'>
        <div className='contact-us_header'>
            <h1>Get In Touch With Us</h1>
        </div>
        <div className='contact-us_form'>
            <Form />
        </div>
    </div>
  )
}

export default Contact
