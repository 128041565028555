import React from 'react'
import "./services.css"
import { ServiceCard } from '../../components'

const Services = () => {
  return (
    <div className='our-services' id='services'>
      <h1>Our Services</h1>
      <div className='our-services_cards'>
        <ServiceCard 
        title="Custom GPT Chatbots"
        text="GPT-3 powered chatbots trained on your own custom data."
        />
        <ServiceCard 
        title="Chatbot Builder"
        text="Coming soon..."
        />
        <ServiceCard 
        title="Data Collection"
        text="Collect valuable data for your business to use in AI solutions."
        />
      </div>
    </div>
  )
}

export default Services
