import React from 'react'
import "./team.css"
import { Card } from '../../components'
import nicolay from "../../assets/nicolay.png"
import liam from "../../assets/liam.png"

const Team = () => {
  return (
    <div className='team'>
        <h1>Our Team</h1>
        <div className='team-cards section-padding'>
            <Card
                img={nicolay}
                name="Nicolay Andre Olsen"
                title="Founder and CEO"
                linkedIn="https://www.linkedin.com/in/nicolay-andre-o-0b120b204/"
            />
            <Card
                img={liam}
                name="Liam Shaun Gillingham"
                title="Head of Operations"
            />
        </div>      
    </div>
  )
}

export default Team
