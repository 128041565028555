import React from 'react'
import "./hero.css"

const Hero = () => {
  return (
    <div className='hero section-padding'>
        <div className='background-image'></div>
        <div className='hero-content'>
            <h1>OmiumAI</h1>    
            <p>
                Automate Your Business with our AI Solutions,
                <br/>Increasing Your Productivity.
            </p>
            <a target="_blank" rel="noopener noreferrer" href='https://calendly.com/omiumai/free-30-min-consultation-call'><button>Book a free Consultation</button></a>
        </div>      
    </div>
  )
}

export default Hero
