import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <div className='footer' id="contact">
      <div className='footer-content'>
        <h1>Omium</h1>
        <p>Norway</p>
        <div className='footer-content_email'>
          <a target="_blank" rel="noopener noreferrer" href="mailto:contact@omium.co">contact@omium.co</a>        
        </div>
      </div>
    </div>
  )
}

export default Footer
