import React, { useEffect } from 'react';
import './App.css';
import { Footer, Navbar } from './components';
import { About, Banner, Contact, Hero, Services, Team } from './containers';
import MainRoutes from './routes/MainRoutes';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-YES097R9MY');

const App = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.botpress.cloud/webchat/v0/inject.js';
    script.async = true;
    document.body.appendChild(script);

    const configScript = document.createElement('script');
    configScript.src = 'https://mediafiles.botpress.cloud/4f402bd0-fe09-433a-8d0e-ccd1c818a107/webchat/config.js';
    configScript.defer = true;
    document.body.appendChild(configScript);

    const styleScript = document.createElement('link');
    styleScript.rel = 'stylesheet';
    styleScript.href = 'https://drive.google.com/uc?export=view&id=1_vE1-eRuBeHIzgb4f3t70WWMUYIx7Q1z';
    document.head.appendChild(styleScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(configScript);
    };
  }, []);
  return (
    <div className="app">
        {/* The Botpress chatbot scripts are dynamically injected here */}
      <MainRoutes />
    </div>
  );
};

export default App;
